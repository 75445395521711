@font-face {
  font-family: "futura";
  src: url("../fonts/futura light bt.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "futura";
  src: url("../fonts/Futura Book font.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "futura";
  src: url("../fonts/futura medium bt.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "futura";
  src: url("../fonts/Futura Heavy font.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "futura";
  src: url("../fonts/Futura Bold font.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "futura";
  src: url("../fonts/Futura XBlk BT.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
